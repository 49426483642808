import React from "react";
import Homepage from "pages/homepage";
import StaticPageMeta from "../components/UI/staticPageMeta";

const Index = (props) => {
    return (
        <>
            <StaticPageMeta pageName="Homepage" />
            <Homepage {...props} />
        </>
    );
};

export default Index;
